.questionnaire {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
  }
  
  .questionnaire label {
    display: block;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .questionnaire select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
  }

  .questionnaire select:focus {
    border-color: #007bff;
    outline: none;
  }
  
  
  .questionnaire button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .questionnaire button:hover {
    background-color: #0056b3;
  }
  

  .download-section {
    margin-top: 20px;
    text-align: center;
  }
  
  .download-button {
    display: inline-block;
    background-color: #007bff;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .download-button:hover {
    background-color: #0056b3;
  }
  